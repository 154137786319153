<template>
    <modal ref="editarActividad" tamano="modal-md" titulo="Editar actividad" adicional="Editar" @adicional="editarActividad">
        <div class="my-2 mx-4">
            <p class="input-label-top">Nombre de la actividad</p>
            <el-input v-model="nombreActividad" class="w-100" size="small" />
            <p class="f-13 text-muted mt-4">Archivos adjuntos</p>
            <div class="d-middle flex-wrap mt-3 gap-1">
                <div v-for="(adjunto, index) in adjuntos" :key="index" class="text-muted d-middle justify-content-between border br-5" style="width: 200px;">
                    <p class="tres-puntos f-12 ml-2">{{ adjunto.archivo }}</p>
                    <div class="br-3 bg-5d text-white">
                        <i class="icon-window-close f-16" @click="deleteAdjunto(adjunto.id)" />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreActividad: '',
            actividad: {},
            adjuntos: []
        }
    },
    methods: {
        toggle(actividad){
            this.actividad = actividad
            this.nombreActividad = this.actividad.actividad
            this.adjuntos = this.actividad.adjuntos
            this.$refs.editarActividad.toggle()
        },
        editarActividad(){
            let adjuntos = this.adjuntos.map(el => el.id)
            const params = {
                id_control_pisos_lista_chequeo: this.actividad.id_lista_chequeo,
                id_configuracion_control_piso_lista_chequeo_actividades: this.actividad.id,
                payload: {
                    nombre: this.nombreActividad,
                    peso: this.actividad.peso,
                    adjuntos
                }
            }
            this.$emit('editarActividad', params)
            this.$refs.editarActividad.toggle()
        },
        deleteAdjunto(id){
            this.adjuntos = this.adjuntos.filter( f => f.id !== id)
        }
    }
}
</script>

<style lang="css" scoped>
.g{
    width: 200px;
}
</style>
